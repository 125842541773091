import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DevStore {
  isGADebugEnabled: boolean;
  toogleGADebug: () => void;
}

export const useDevStore = create<DevStore>()(
  persist(
    (set) => ({
      isGADebugEnabled: false,
      toogleGADebug: () =>
        set((state) => ({ isGADebugEnabled: !state.isGADebugEnabled })),
    }),
    {
      name: 'dev-storage',
      skipHydration: process.env.NODE_ENV !== 'development',
    }
  )
);
