import { useRef } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';

import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import { ApplicationLayoutBodyNavigation } from '@rio-cloud/rio-uikit';
import { ModulePropType } from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import SubNavigation from '@rio-cloud/rio-uikit/SubNavigation';

import DefaultRedirect from '@/routes/DefaultRedirect';
import {
  ADMINISTRATION_CARDS,
  ADMINISTRATION_CONTRACT,
  CHARGING_ACTIVITY,
  DEFAULT_ROUTE,
  INVOICES,
} from '@/routes/Router';

import { useAccountStore } from '@/stores/accountStore';
import { useAppStore } from '@/stores/appStore';
import { useLocaleStore } from '@/stores/localeStore';
import { useUserStore } from '@/stores/userStore';

import { DEFAULT_LOCALE } from '@/configuration/lang/lang';

import { AppContext } from '@/layout/AppContext';

import NotFoundError from '@/pages/NotFound';

import { GuidedTourModal } from '@/features/guidedTour/components/Modal';
import AppHeader from '@/features/header/AppHeader';

import { NavigationLink } from '@/components/NavigationLink';
import { SubNavigationLink } from '@/components/SubNavigationLink';

import { ModalContextProvider } from '@/hooks/useModal';

import { includesAny } from '@/lib/utils';

export interface NavItemType extends ModulePropType {
  permissions: string[];
}

const AppLayout = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { displayLocale, displayMessages } = useLocaleStore();
  const { userSessionExpired } = useAccountStore();
  const { sessionExpiredAcknowledged, hideSessionExpiredDialog } =
    useAppStore();
  const showSessionExpired = userSessionExpired && !sessionExpiredAcknowledged;
  const { isEligible, account } = useUserStore();
  const permissions = account?.permissions;
  if (!(displayMessages && displayLocale)) {
    return null;
  }

  const navItems: NavItemType[] = [
    {
      key: 'overview',
      permissions: ['emsp-dashboard'],
      route: (
        <NavigationLink to={DEFAULT_ROUTE}>
          <FormattedMessage id={'general.mainNavigation.overview'} />
        </NavigationLink>
      ),
    },
    {
      key: 'charging',
      permissions: ['emsp-charging-sessions.read', 'emsp-invoices.read'],
      route: (
        <NavigationLink to={CHARGING_ACTIVITY}>
          <FormattedMessage id={'general.mainNavigation.chargingActivity'} />
        </NavigationLink>
      ),
    },
    {
      key: 'invoices',
      permissions: ['emsp-invoices.read'],
      route: (
        <NavigationLink to={INVOICES}>
          <FormattedMessage id={'general.subNavigation.invoices'} />
        </NavigationLink>
      ),
    },
    {
      key: 'administration',
      permissions: ['emsp-cards.read'],
      route: (
        <NavigationLink to={ADMINISTRATION_CARDS}>
          <FormattedMessage id={'general.mainNavigation.administration'} />
        </NavigationLink>
      ),
    },
  ];
  const subNavItems: NavItemType[] = [
    {
      key: 'administration.cards',
      permissions: ['emsp-cards.read'],
      route: (
        <SubNavigationLink to={ADMINISTRATION_CARDS}>
          <FormattedMessage id={'general.subNavigation.chargingCards'} />
        </SubNavigationLink>
      ),
    },
    {
      key: 'administration.contract',
      permissions: ['emsp-contract.read'],
      route: (
        <SubNavigationLink to={ADMINISTRATION_CONTRACT}>
          <FormattedMessage id={'general.subNavigation.chargingContract'} />
        </SubNavigationLink>
      ),
    },
  ];

  const allowedNavItems =
    isEligible && account?.isOnboarded
      ? navItems.filter((item) => includesAny(permissions, item.permissions))
      : [];
  const allowedSubNavItems =
    isEligible && account?.isOnboarded
      ? subNavItems.filter((item) => includesAny(permissions, item.permissions))
      : [];

  const subNavForPath = allowedSubNavItems?.filter((item: { key: string }) => {
    const mainSegment = item.key.split('.')[0];
    return pathname.split('/').includes(mainSegment);
  });

  const customOnErrorHandler = (error: any) => {
    if (error.code === 'MISSING_TRANSLATION' || error.code === 'FORMAT_ERROR') {
      return null;
    } else {
      console.error(error);
    }
  };

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      key={displayLocale}
      locale={displayLocale}
      messages={displayMessages}
      onError={customOnErrorHandler}
    >
      <ModalContextProvider>
        <AppContext.Provider value={{ sidebarRef }}>
          <ApplicationLayout data-testid={'app-layout'}>
            <ApplicationLayout.Header>
              <AppHeader navItems={allowedNavItems} />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
            <ApplicationLayout.Body
              navigation={
                <ApplicationLayoutBodyNavigation className="has-offset">
                  <SubNavigation
                    className="!overflow-hidden"
                    navItems={subNavForPath}
                  />
                </ApplicationLayoutBodyNavigation>
              }
            >
              <main>
                <NotificationsContainer />
                {account?.isOnboarded && <GuidedTourModal />}
                <SessionExpiredDialog
                  locale={displayLocale}
                  onClose={hideSessionExpiredDialog}
                  show={showSessionExpired}
                />
                {isEligible ? <Outlet /> : <NotFoundError />}
                <DefaultRedirect />
              </main>
            </ApplicationLayout.Body>
          </ApplicationLayout>
        </AppContext.Provider>
      </ModalContextProvider>
    </IntlProvider>
  );
};

export default AppLayout;
