import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  createDebugConfig,
  trackPageView,
} from '@/configuration/setup/googleAnalytics';

import { pageTitles } from '@/routes/Router';

let isLinkNavigation = false;

export const setLinkNavigation = () => {
  isLinkNavigation = true;
  // Reset the flag after a short delay to ensure it's handled by the useEffect
  setTimeout(() => {
    isLinkNavigation = false;
  }, 100);
};

export const usePageTracking = (): void => {
  const location = useLocation();
  const debugConfig = createDebugConfig();

  useEffect(() => {
    if (isLinkNavigation) {
      return;
    }

    const page = pageTitles.find(({ page }) =>
      location.pathname.startsWith(page)
    );

    if (page) {
      trackPageView(
        {
          page_location: `${window.location.origin}${location.pathname}${location.search}${location.hash}`,
          page_title: page.title,
        },
        debugConfig
      );
    }
  }, [location]);
};
